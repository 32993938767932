import axios from 'axios';
import process from 'process';

const apiBaseUrl = process.env.REACT_APP_API_URL

const fetchHelper = async (endpoint, method = 'GET', data = null, headers) => {
  try {
    const response = await axios({
      method,
      url: `${apiBaseUrl}${endpoint}`,
      data,
      headers,
    });

    if (response.status === 200) {
      return response.data;
    } else {
      throw new Error(`API request failed with status ${response.status}`);
    }
  } catch (error) {
    console.error('API request error:', error);
    throw error;
  }
};

export default fetchHelper;