import React, { useState } from 'react'
import useStickerNumber from '../Store/StickerStore'
import useAuthStore from '../Store/authStore';
import { validatesticker } from '../Constants/endpoints';
import fetchHelper from '../Helper/Fetch';
import LoadingIndicator from './LoadingIndicator';
import { toast } from 'react-toastify';
import EnumerationForm from './EnumerationForm';
import useStickerStatus from '../Store/StickerStatusStore';
import VehicleInfoCard from './VehicleInfoCard';

const ValidateSticker = () => {
    const { sticker_number } = useStickerNumber();
    const [serialNumber, setSerialNumber] = useState('');
    const { token, user } = useAuthStore();
    const [loading, setLoading] = useState(false)
    const [stickerDetails, setStickerDetails]=useState({})
    const { sticker_status, setStickerStatus, resetStickerStatus } = useStickerStatus();

    const handleSubmit = async (e) => {
        e.preventDefault();
        setLoading(true)

        const headers = {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`,
        };

        try {
          const data = await fetchHelper(validatesticker + "?sticker_number="+sticker_number+"&serial="+serialNumber, 'GET', null, headers);
          if (data.status_code=="01"){
            toast.error('Invalid Sticker');
            setLoading(false)
            setStickerStatus("invalid");
            return
          }else if(data.status_code=="02"){
            toast.success('Success');
            setStickerStatus("valid");
            setLoading(false)
            return
          }else if(data.status_code=="00"){
            toast.success('Success');
            console.log("The sticker is used")
            setStickerStatus("used");
            setStickerDetails(data.data)
            setLoading(false)
          }
        } catch (error) {
            setLoading(false)
            toast.error('Error getting sticker details');
          console.log(error)
        }

    };
  
    return (<>
    <div className='mt-3'>
      {loading && <div className='opacity-50'><LoadingIndicator /></div>}
      {(sticker_status=="invalid" || sticker_status=="") && <form onSubmit={handleSubmit} className="max-w-md mx-auto p-6 bg-white shadow-md rounded-md">
      <h2 className="text-2xl font-semibold mb-6 text-green-600">Validate Sticker</h2>
      <div className="mb-4">
        <label className="block text-sm font-medium text-gray-700">Sticker Number</label>
        <input
          type="text"
          value={sticker_number} // Default value from Zustand store
          readOnly // This field is not editable
          className="mt-1 block w-full p-2 border border-green-400 rounded-md bg-gray-100 focus:outline-none focus:ring-2 focus:ring-green-500 focus:border-green-500"
        />
      </div>

      <div className="mb-6">
        <label className="block text-sm font-medium text-gray-700">Serial Number</label>
        <input
          type="text"
          value={serialNumber}
          onChange={(e) => setSerialNumber(e.target.value)} // Update serial number
          required
          className="mt-1 block w-full p-2 border border-green-400 rounded-md focus:outline-none focus:ring-2 focus:ring-green-500 focus:border-green-500"
          placeholder="Enter Serial Number"
        />
      </div>

      <button
        type="submit"
        className="w-full bg-green-500 text-white py-2 px-4 rounded-md hover:bg-green-600 focus:outline-none focus:ring-2 focus:ring-green-500 focus:ring-offset-2 transition duration-300"
      >
        Submit
      </button>
    </form>}
    {sticker_status=="valid" && <EnumerationForm />}
    {sticker_status=="used" && <VehicleInfoCard data={stickerDetails}/>}
    </div>
    </>
    )
}

export default ValidateSticker