import { create } from 'zustand';
import { persist } from 'zustand/middleware';

const initialState = {
  token: null,
  user: null,
  isLoading: false,
  error: null,
};

const useAuthStore = create(
  persist(
    (set) => ({
      ...initialState,
      setToken: (token) => set({ token }),
      setUser: (user) => set({ user }),
      setLoading: (isLoading) => set({ isLoading }),
      setError: (error) => set({ error }),
      authReset: () => set({ ...initialState }),
    }),
    {
      name: 'auth-store',
      storage: localStorage,
      // Custom serialization/deserialization (optional)
      serialize: (state) => {
        // ... (your custom serialization logic)
        return JSON.stringify(state);
      },
      deserialize: (state) => {
        // ... (your custom deserialization logic)
        return JSON.parse(state);
      },
    }
  )
);

export default useAuthStore;