import { create } from 'zustand';

const useStickerNumber = create((set) => ({
  // Initial state
  sticker_number: '',

  // Function to set the sticker number
  setStickerNumber: (sticker_number) => set(() => ({ sticker_number })),

  // Function to reset the sticker number
  resetStickerNumber: () => set(() => ({ sticker_number: '' })),
}));

export default useStickerNumber;
