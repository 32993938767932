import React, {useEffect} from 'react';
import Header from './Header';
import useAuthStore from '../Store/authStore';


const Information = () => {
    const { token, user,authReset } = useAuthStore();

    useEffect(() => {
        console.log(user)
      if (!token) {
        window.location.href = '/';
      }
    }, [token]);

    return (
        <div>
            <table>
                <tr>
                    <td className="font-bold">Name: </td>
                    <td>{user?.first_name } {" "} {user?.middle_name } {" "} {user?.last_name } </td>
                </tr>

                <tr>
                    <td className="pr-1 font-bold">Phone: </td>
                    <td>{user?.phone } </td>
                </tr>
                
            </table>
        </div>
    )
}

export default Information