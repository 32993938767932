import React from 'react';

const StatsCard = ({title, value}) => {
  return (
    <div className="bg-green-100 shadow-lg rounded-lg p-6 flex flex-col items-center justify-between text-green-900 w-full max-w-sm mx-auto sm:max-w-xs md:max-w-md lg:max-w-lg">      
      <div className="text-center">
        <h2 className="text-lg font-medium">{title}</h2>
        <p className="text-3xl font-bold mt-2">{value}</p>
      </div>
    </div>
  );
};

export default StatsCard;
