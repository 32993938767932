import React, {useEffect, useState } from 'react'
import Header from './Header'
import QrCodeScanner from './QRCodeScanner'
import useStickerNumber from '../Store/StickerStore'
import ValidateSticker from './StickerValidation'
import useStickerStatus from '../Store/StickerStatusStore'

const Enumeration = () => {
    const { sticker_number, setStickerNumber, resetStickerNumber } = useStickerNumber();
    const { sticker_status, setStickerStatus, resetStickerStatus } = useStickerStatus();
    const naviageBack = () => {
        window.location.href="/search"
    }

    useEffect(()=>{

    }, [])
    return (<div>
       <div className="bg-white min-h-full">
            <Header />
            <button 
                onClick={naviageBack}
                className="bg-green-600 mt-2 rounded-lg px-4 text-white ml-4">Back</button>
            
            {!sticker_number && <QrCodeScanner />}
            {sticker_number && <ValidateSticker sticker_number={sticker_number}/>}
        </div>
    </div>)
}

export default Enumeration