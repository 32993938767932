import React, { useEffect, useState, useRef } from 'react';
import { Html5Qrcode } from 'html5-qrcode';
import ExtractStickerNumber from './ExtractStickerNumber';
import useStickerStore from '../Store/StickerStore';

const QrCodeScanner = () => {
  const [scanResult, setScanResult] = useState(null);
  const [errorMessage, setErrorMessage] = useState(null);
  const [scannerRunning, setScannerRunning] = useState(false);
  const qrCodeRegionId = "reader";
  const html5QrCodeRef = useRef(null); // Ref to store the Html5QrCode instance
  const { sticker_number, status, license_plate, vehicle_type, setStickerDetails, resetSticker } = useStickerStore();

  useEffect(() => {
    if (!html5QrCodeRef.current) {
      const html5QrCode = new Html5Qrcode(qrCodeRegionId);
      html5QrCodeRef.current = html5QrCode;

      const config = { fps: 10, qrbox: 250, aspectRatio: 1.3333 };

      const qrCodeSuccessCallback = (decodedText, decodedResult) => {
        setScanResult(decodedText);
        stopScanner();
      };

      const qrCodeErrorCallback = (error) => {
        setErrorMessage(error);
      };

      html5QrCode.start(
        { facingMode: "environment" },  // Use the back camera
        config,
        qrCodeSuccessCallback,
        qrCodeErrorCallback
      )
        .then(() => setScannerRunning(true))
        .catch((err) => {
          console.error('Failed to start scanning:', err);
          setErrorMessage(err.message);
        });
    }

    const stopScanner = () => {
      if (html5QrCodeRef.current && scannerRunning) {
        html5QrCodeRef.current.stop()
          .then(() => {
            html5QrCodeRef.current.clear();
            setScannerRunning(false);
          })
          .catch((err) => console.error('Failed to stop scanning:', err));
      }
    };

    return () => {
      stopScanner(); // Stop scanner on component unmount
    };
  }, [scannerRunning]);

  return (
    <div>

      {!scanResult && <div id={qrCodeRegionId} style={{ width: "100%" }} /> }
      {/* {scanResult && <p>Scanned Result: {scanResult}</p>} */}
      {scanResult && <ExtractStickerNumber url={scanResult}/>}
      {/* {errorMessage && <p>Error: {errorMessage}</p>} */}
    </div>
  );
};

export default QrCodeScanner;
