import React, { useState , useRef} from 'react'
import useAuthStore from '../Store/authStore'
import Webcam from 'react-webcam';
import fetchHelper from '../Helper/Fetch';
import { closeshf } from '../Constants/endpoints';
import { toast } from 'react-toastify';
import useModalStore from "../Store/modalState"


const SHFDetails = ({results, shf_id}) => {
    const { user, token } = useAuthStore();
    const webcamRef = useRef(null);
    const [image, setImage] = useState(null);
    const [loading, setLoading] = useState(false);
    const [readyToCapture, setReadyToCapture]= useState(false)
    const { closeModal } = useModalStore(state => ({
        closeModal: state.closeModal
    }));
    
    const videoConstraints = {
        facingMode: "environment", // Use the back camera
    };

    const capture = () => {
        const imageSrc = webcamRef.current.getScreenshot();
        setImage(imageSrc);
    };
    const conclude = async() => {
        let url = closeshf+shf_id+"/close"
        const formDataToSend = new FormData();
        if (image) {
            const response = await fetch(image);  // image is the Base64 string
            const blob = await response.blob();   // convert Base64 to blob
            formDataToSend.append('vehicle_image', blob, 'vehicle_image.jpg');
        }
        const headers = {
            Authorization: `Bearer ${token}`
        };

        const response = await fetchHelper(url, 'POST', formDataToSend, headers)
        console.log(response)
        if(response.statusCode=="00"){
            toast.success("SHF Transaction concluded");
            closeModal()
        }else{
            toast.success("Error concluding SHF transaction");
        }
    }
    return (
        <>  <div className='capitalize border-b-2 border-b-green-500 mt-4'>Current State: <span className='font-bold text-green-500'>{user.state}</span></div>
            <div className='capitalize border-b-2 border-b-green-500 mt-4'>Current LG: <span className='font-bold text-green-500'>{user.lga}</span></div>
            <table className='leading-8'>
                { results.origin_state && <tr>
                    <td className="pr-1 text-sm font-bold">SHF Origin: </td>
                    <td>{results?.origin_state} </td>
                </tr>}
                { results.origin_lg && <tr className='border-b-2 border-b-green-500'>
                    <td className="pr-1 text-sm font-bold">SHF Local Government: </td>
                    <td>{results?.origin_lg} </td>
                </tr>}
                {results.first_stopover_state && 
                <tr>
                    <td className="pr-1 text-sm font-bold">First StopOver State: </td>
                    <td>{results?.first_stopover_state} </td>
                </tr> }
                
                {results.first_stopover_lga && <tr className='border-b-2 border-b-green-500'>
                    <td className="pr-1 text-sm font-bold">First StopOver Local Government: </td>
                    <td>{results?.first_stopover_lga} </td>
                </tr>}
                
                {results.second_stopover_state && <tr>
                    <td className="pr-1 text-sm font-bold">Second StopOver State: </td>
                    <td>{results?.second_stopover_state} </td>
                </tr>}
                {results.second_stopover_lga && <tr className='border-b-2 border-b-green-500'>
                    <td className="pr-1 text-sm font-bold">Second StopOver Local Government: </td>
                    <td>{results?.second_stopover_lga} </td>
                </tr>}

                {results.third_stopover_state && <tr>
                    <td className="pr-1 text-sm font-bold">Third StopOver State: </td>
                    <td>{results?.third_stopover_state} </td>
                </tr>}
                {results.third_stopover_lga && <tr className='border-b-2 border-b-green-500'>
                    <td className="pr-1 text-sm font-bold">Third StopOver Local Government: </td>
                    <td>{results?.third_stopover_lga} </td>
                </tr>}

                {results.destination_state && <tr>
                    <td className="pr-1 text-sm font-bold">Destination State: </td>
                    <td>{results?.destination_state} </td>
                </tr>}
                {results.destination_lg && <tr className='border-b-2 border-b-green-500'>
                    <td className="pr-1 text-sm font-bold">Destination Local Government: </td>
                    <td>{results?.destination_lg} </td>
                </tr>}
            </table>
      
            {results?.destination_lg.toLowerCase() == user.lga.toLowerCase() && <button onClick={()=>{setReadyToCapture(true)}} className='mt-4 bg-red-500 rounded-lg px-1 text-gray-100'>Conclude SHF Transaction</button>}

            {(readyToCapture && !image) && <div className="mt-4">
                <Webcam
                audio={false}
                ref={webcamRef}
                screenshotFormat="image/jpeg"
                videoConstraints={videoConstraints}
                onClick={capture}
            /> </div>}
            
            {image && (
                        <div className='mt-4'>
                            <img src={image} alt="Captured" />
                            <button disabled={loading} onClick={conclude} className='mt-2 bg-green-600 rounded-lg text-white'>
                                {loading ? 'Uploading...' : 'Close Transaction'}
                            </button>
                            <button className="bg-red-400 rounded-lg ml-3 px-1 text-white" onClick={()=>{setImage(null); setReadyToCapture(true)}}>Recapture</button>
                        </div>
                        )}
                    </>
                )
            }

export default SHFDetails