import React, {useState, useRef, useEffect} from 'react';
import { CSSTransition } from 'react-transition-group';
import logo  from '../Images/logo.png'
import { logout } from '../Constants/endpoints';
import fetchHelper from '../Helper/Fetch';
import useAuthStore from '../Store/authStore';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCog } from '@fortawesome/free-solid-svg-icons';
import { faSignOutAlt } from '@fortawesome/free-solid-svg-icons';   



const Header = () => {
    const [menuState, setMenuState] = useState("collapsed")
    const { token, user,authReset } = useAuthStore();
    const menuRef = useRef(null);

    const NavigateProfile = () => {
        // setMenuState(menuState === "collapsed" ? "expanded" : "collapsed")
        window.location.href = '/profile';
    }

    const Logout = async (e) => {
      e.preventDefault();

      const headers = {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`
       };

       const data = await fetchHelper(logout, 'GET', null, headers);
       if (data){
          authReset();
          window.location.href = '/';
       }
    }

    const handleOutsideClick = (event) => {
      if (menuRef.current && !menuRef.current.contains(event.target)) {
          setMenuState('collapsed');
      }
    };

    useEffect(() => {
      document.addEventListener('mousedown', handleOutsideClick);
      return () => {
          document.removeEventListener('mousedown', handleOutsideClick);   
      };
    }, []);

    const NavigateHome = () => {
        window.location.href = '/search';
    }

    return (
        <header className="bg-green-800 text-white py-4">
        <div className="flex justify-between items-center px-4">
          <div className="flex items-center">
            <img src={logo} alt="Logo" onClick={NavigateHome} className="w-24 h-10" />
            <span className="ml-2 text-lg font-bold">Enforcement App</span>
          </div>
          <button id="menu-button" className="text-white hover:text-gray-400 focus:outline-none" onClick={NavigateProfile}>
            <FontAwesomeIcon icon={faCog} />
          </button>
          <button id="menu-button" className="-ml-10 text-white hover:text-gray-400 focus:outline-none" onClick={Logout}>
            <FontAwesomeIcon icon={faSignOutAlt} />
          </button>
        </div>
        <div id="mobile-menu" className={`absolute w-full pr-1 ${menuState === 'collapsed' ? 'hidden':'block'} flex bg-green-800 opacity-80`}>
            <div className='flex-1'></div>
            <div className='flex-1'></div>
            <div className='flex-1'></div>
            <div className='flex-1'></div>
            <div className='flex-1'></div>
            <div className='flex-1'>
            <CSSTransition
                in={true}
                timeout={10000}
                classNames={{
                  enter: 'opacity-0',
                  enterActive: 'transition duration-1000 ease-in-out opacity-1000',
                  exit: 'opacity-100',
                  exitActive: 'transition duration-1000 ease-in-out opacity-0',
                }}
                unmountOnExit
            >
            <ul className="mt-4 text-right">
                <li className="py-2"><button onClick={NavigateHome} className="block text-black hover:text-gray-900">Home</button></li>
                <li className="py-2"><button onClick={Logout} className="block text-black hover:text-gray-900">Profile</button></li>
                <li className="py-2"><button onClick={Logout} className="block text-black hover:text-gray-900">Logout</button></li>
            </ul>
            </CSSTransition>    
            </div>

        </div>
      </header>
    );
};

export default Header;