import React, { useState, useEffect } from 'react';
import Header from '../Component/Header';
import LoadingIndicator from '../Component/LoadingIndicator';
import SearchResult from '../Component/SearchResult';
import useAuthStore from '../Store/authStore';
import { toast } from 'react-toastify';
import fetchHelper from '../Helper/Fetch';
import { search, monitor_stats } from "../Constants/endpoints"
import useModalStore from '../Store/modalState';
import StatsCard from '../Component/StatsCard';

function Monitor() {
    const [searchType, setSearchType] = useState('licensePlate');
    const [searchTerm, setSearchTerm] = useState('');
    const [loading, setLoading] = useState(false);
    const { token, user } = useAuthStore();
    const [stats, setStats] = useState({
        found: 0,
        active_shf:0,
        active_sirts:0,
        total_sent:0
    });

    const getStats = async () => {                  
      const headers = {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`
      };

      // let url = monitor_stats
      const url = `${monitor_stats}?_=${new Date().getTime()}`;
      const data = await fetchHelper(url, 'GET', null, headers);
      console.log("get stats: " , data.data)
      setStats(data.data);
    }

    useEffect(()=>{
      getStats();
    }, [])

    useEffect(() => {
      if (!token) {
        window.location.href = '/';
      }
    }, [token]);

  const handleSearchTypeChange = (e) => {
    setSearchType(e.target.value);
  };

  const handleSearchTermChange = (e) => {
    setSearchTerm(e.target.value);
  };
  const Search = async (e) => {
    setLoading(true)
    e.preventDefault()
    if(searchTerm == ""){
      toast.error('Input License Plate Number);')
      setLoading(false)
      return
    }

    const headers = {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token}`
     };

     let url = search + "?search=" + searchTerm
     const data = await fetchHelper(url, 'GET', null, headers);
      // setEnforcementActivity(data?.vehicle)
      // setStickerId(data.sticker_id)
      // setSHFId(data.shf_id)
      getStats();
      console.log("Log: ", data)
      setLoading(false)
      toast.success("License Plate Number Sent");
  }

  return (
    <div className="bg-white min-h-full">
        <Header />
        <div className="container mx-auto py-10 px-4">
            <div className="relative"> 
                <div className="absolute top-0 left-0 w-full h-full bg-blue-500"> 
                    {loading && <LoadingIndicator />}
                </div>
                <div className="absolute top-0 left-0 right-0 w-full h-full ml-4 bg-red-500"> 
                    <form>
                        <div className="flex flex-col">
                          <div className="flex space-x-4">
                            <label>
                              <input type="radio" value="licensePlate" checked={searchType === 'licensePlate'} onChange={handleSearchTypeChange} />
                              License Plate
                            </label>
                            <label>
                              <input type="radio" value="reference" disabled checked={searchType === 'reference'} onChange={handleSearchTypeChange} />
                              Reference
                            </label>
                          </div>

                          <div className="flex space-x-1">
                          <input type="text" className="border border-gray-300 p-2 mt-4" placeholder="Search" value={searchTerm} onChange={handleSearchTermChange} />
                          <button className="bg-green-500 hover:bg-green-700 text-white font-bold py-2 px-4 rounded mt-4" onClick={Search}>Search</button>
                          </div>
                        </div>
                    </form>
                    <div className='mt-3 mr-5 flex'>
                        <div className='flex-1'>
                            <StatsCard
                              title="Total Sent"
                              value={stats.total_sent}
                            />
                        </div>
                    </div>
                    <div className='mt-3 mr-5 flex'>
                        <div className='flex-1'>
                            <StatsCard
                              title="Active SHF"
                              value={stats.active_shf || 0}
                            />
                        </div>
                        <div className='flex-1'>
                            <StatsCard
                              title="Active SIRTS"
                              value={stats.active_sirts || 0}
                            />
                        </div>
                    </div>
                    {/* <div>
                        <SearchResult results={enforcementActivity} shf_id={shf_id} sticker_id={sticker_id}/>
                    </div> */}

                    {/* <div className="flex items-center justify-center bg-gray-100">
                      <button 
                        className="px-1 py-1 text-sm bg-green-500 text-white rounded-lg shadow-md hover:bg-blue-600 transition"
                        onClick={handleOpenModal}
                      >
                        Report Infraction
                      </button>
                      <button 
                        className="ml-3 px-1 py-1 text-sm bg-green-500 text-white rounded-lg shadow-md hover:bg-blue-600 transition"
                        onClick={navigateToEnumeration}
                      >
                        Scan Sticker
                      </button>
                      <Modal />
                    </div> */}
                </div>
            </div>
        </div>
    </div>
  );
}

export default Monitor;