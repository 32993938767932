import React, { useState, useRef, useEffect } from 'react';
import { useForm } from 'react-hook-form';
import Webcam from 'react-webcam';
import { toast } from 'react-toastify';
import fetchHelper from '../Helper/Fetch';
import { infraction, vehicle_types } from '../Constants/endpoints';
import useAuthStore from '../Store/authStore';
import useModalStore from '../Store/modalState';

const InfractionReportForm = () => {
  const { handleSubmit, control, register, setValue } = useForm();
  const { closeModal } = useModalStore();
  const [vehicleImage, setVehicleImage] = useState(null);
  const [documentImage, setDocumentImage] = useState(null);
  const [showCamera, setShowCamera] = useState(false);
  const [imageType, setImageType] = useState('');
  const webcamRef = useRef(null);
  const [vehicleTypes, setVehicleTypes] = useState([]);
  const { token, user } = useAuthStore();
  const [formData, setFormData] = useState({
    license_plate: '',
    vehicle_image:'',
    scanned_document: '',
    vehicle_type: '',
    infraction_type: '',
    note: '',
  });
  const constraints = {
    audio: false,
    video: { facingMode: "environment" } // Request rear camera
};

const onSubmit = async (data) => {
  try {
    const formDataToSend = new FormData();
    formDataToSend.append('license_plate', data.licensePlate);
    formDataToSend.append('vehicle_type', data.vehicleType);
    formDataToSend.append('infraction_type', formData.infraction_type);
    formDataToSend.append('note', data.note);

    // Append images if they exist
    if (vehicleImage) {
      const blob = await fetch(vehicleImage).then(res => res.blob());
      formDataToSend.append('vehicle_image', blob, 'vehicle_image.jpg');
    }

    if (documentImage) {
      const blob = await fetch(documentImage).then(res => res.blob());
      formDataToSend.append('scanned_document', blob, 'document_image.jpg');
    }


    // Add Authorization Header if needed
    const headers = {
      'Authorization': `Bearer ${token}`,
    };

    // Send the form data to your backend
    const response = await fetchHelper(infraction, 'POST', formDataToSend, headers);
    console.log(response);
    if (response.statusCode=="00") {
      toast.success("Form submitted successfully!");
      closeModal();
    } else {
      toast.error("Form submission failed.");
    }
  } catch (error) {
    console.error("Error submitting form:", error);
    toast.error("An error occurred. Please try again.");
  }
};


  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  useEffect(() => {
    const headers = {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token}`
    };
    const get_vehicle_type = async() =>{
      const response = await fetchHelper(vehicle_types, "GET", null, headers)
      console.log(response);
      console.log(response.data);
      setVehicleTypes(response.data); // Assuming 'data' contains the array of vehicle types
    }
    get_vehicle_type();

  }, []);

  const capture = () => {
    const imageSrc = webcamRef.current.getScreenshot();
    if (imageType === 'vehicle') {
      setVehicleImage(imageSrc);
    } else if (imageType === 'document') {
      setDocumentImage(imageSrc);
    }
    setShowCamera(false);
  };

  return (
    <div className="p-6 max-w-lg mx-auto bg-white rounded-lg shadow-md">
      <h2 className="text-xl font-bold mb-4">Infraction Report Form</h2>
      
      {showCamera && (
        <div className="absolute inset-0 bg-black bg-opacity-50 flex items-center justify-center">
          <div className="relative bg-white p-4 rounded-lg">
            <Webcam
              audio={false}
              ref={webcamRef}
              screenshotFormat="image/jpeg"
              videoConstraints={{ facingMode: "environment" }} // Ensures the back camera is used
              width={250}
              height={250}
              className="mb-4"
            />
            <button
              onClick={capture}
              className="px-4 py-2 bg-green-500 text-white rounded-md mr-2"
            >
              Capture
            </button>
            <button
              onClick={() => setShowCamera(false)}
              className="px-4 py-2 bg-red-500 text-white rounded-md"
            >
              Cancel
            </button>
          </div>
        </div>
      )}

      <form onSubmit={handleSubmit(onSubmit)} className="space-y-4">
      <div>
          <label className="block text-sm font-medium text-gray-700">Infaction Type</label>
          <select name="infraction_type" {...register('infraction_type')} onChange={handleChange} className="w-full p-2 border rounded focus:outline-none focus:ring-2 focus:ring-green-400">
            <option value="">Select Infraction Type</option>
            <option value="Hit and Run">Hit and Run</option>
            <option value="Fake Document">Fake Document</option>
            <option value="Wrong Enumeration">Wrong Enumeration</option>
            <option value="General Complaint">General Complaint</option>
          </select>
        </div>
        <div>
          <label className="block text-sm font-medium text-gray-700">License Plate Number</label>
          <input
            type="text"
            {...register('licensePlate')}
            className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm"
          />
        </div>

        <div>
          <label className="block text-green-700 font-semibold mb-2">Vehicle Type</label>
          <select
            name="vehicleType"
            // value={formData.vehicle_type}
            onChange={handleChange}
            {...register('vehicleType')}
            required
            // className="mt-1 block w-full border-gray-300 rounded-md shadow-sm focus:ring-green-500 focus:border-green-500 sm:text-sm"\
            className="w-full p-2 border rounded focus:outline-none focus:ring-2 focus:ring-green-400"
          >
            <option value="">Select Vehicle Type</option>
            {vehicleTypes.length > 0 ? (
              vehicleTypes.map((vehicle, index) => (
                <option key={index} value={vehicle.name}>{vehicle.name}</option>
                  ))
                ) : (
                  <option value="" disabled>No vehicle types available</option>
                )}
          </select>
        </div>

       {(formData.infraction_type=="Hit and Run" || formData.infraction_type=="Wrong Enumeration" || formData.infraction_type=="General Complaint")  && <div>
          <label className="block text-sm font-medium text-gray-700">Vehicle Image</label>
          <button
            type="button"
            onClick={() => {
              setImageType('vehicle');
              setShowCamera(true);
            }}
            className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm bg-green-500 text-white hover:bg-green-600"
          >
            Capture Image
          </button>
          {vehicleImage && (
            <div className="mt-2">
              <img src={vehicleImage} alt="Vehicle" className="w-full h-auto border border-gray-300 rounded-md" />
            </div>
          )}
        </div>} 

        {(formData.infraction_type=="Fake Document" || formData.infraction_type=="General Complaint") && <div>
          <label className="block text-sm font-medium text-gray-700">Scanning of Document</label>
          <button
            type="button"
            onClick={() => {
              setImageType('document');
              setShowCamera(true);
            }}
            className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm bg-green-500 text-white hover:bg-green-600"
          >
            Capture Document
          </button>
          {documentImage && (
            <div className="mt-2">
              <img src={documentImage} alt="Document" className="w-full h-auto border border-gray-300 rounded-md" />
            </div>
          )}
        </div>}

        <div>
          <label className="block text-sm font-medium text-gray-700">Note</label>
          <textarea
            {...register('note')}
            className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm"
          />
        </div>

        <button
          type="submit"
          className="w-full px-4 py-2 bg-green-500 text-white rounded-lg shadow-md hover:bg-green-600"
        >
          Submit Report
        </button>
      </form>
    </div>
  );
};

export default InfractionReportForm;
