import React from 'react';

const VehicleInfoTable = ({ data }) => {
  const {
    FullName,
    Address,
    Email,
    Phone,
    State,
    LGA,
    VehicleType,
    VehicleLicense,
    StickerNumber
  } = data;

  return (
    <div className="bg-white border border-green-300 shadow-lg rounded-lg p-8 max-w-lg mx-auto mt-8">
      <h1 className="text-3xl font-bold text-green-800 mb-6 text-center">Vehicle Information</h1>
      
      <table className="min-w-full table-auto">
        <tbody>
          <tr className="border-b">
            <td className="py-2 px-4 font-semibold text-gray-700">Full Name</td>
            <td className="py-2 px-4 text-gray-900">{FullName}</td>
          </tr>
          <tr className="border-b">
            <td className="py-2 px-4 font-semibold text-gray-700">Address</td>
            <td className="py-2 px-4 text-gray-900">{Address}</td>
          </tr>
          <tr className="border-b">
            <td className="py-2 px-4 font-semibold text-gray-700">Email</td>
            <td className="py-2 px-4 text-gray-900">{Email ? Email : "N/A"}</td>
          </tr>
          <tr className="border-b">
            <td className="py-2 px-4 font-semibold text-gray-700">Phone</td>
            <td className="py-2 px-4 text-gray-900">{Phone}</td>
          </tr>
          <tr className="border-b">
            <td className="py-2 px-4 font-semibold text-gray-700">State</td>
            <td className="py-2 px-4 text-gray-900">{State}</td>
          </tr>
          <tr className="border-b">
            <td className="py-2 px-4 font-semibold text-gray-700">LGA</td>
            <td className="py-2 px-4 text-gray-900">{LGA}</td>
          </tr>
          <tr className="border-b">
            <td className="py-2 px-4 font-semibold text-gray-700">Vehicle Type</td>
            <td className="py-2 px-4 text-gray-900">{VehicleType}</td>
          </tr>
          <tr className="border-b">
            <td className="py-2 px-4 font-semibold text-gray-700">Vehicle License</td>
            <td className="py-2 px-4 text-gray-900">{VehicleLicense}</td>
          </tr>
          <tr className="border-b">
            <td className="py-2 px-4 font-semibold text-gray-700">Sticker Number</td>
            <td className="py-2 px-4 text-gray-900">{StickerNumber}</td>
          </tr>
        </tbody>
      </table>
    </div>
  );
};

export default VehicleInfoTable;
