import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import * as serviceWorkerRegistration from './serviceWorkerRegistration'; // Import this
import { createRoot } from 'react-dom/client';

const root = createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>
);
// Register the service worker
serviceWorkerRegistration.register({
  onUpdate: (registration) => {
    if (registration && registration.waiting) {
      // Notify the user about the update
      console.log('A new version is available! Ready to install');
      
      if (window.confirm("New version available! Would you like to update?")) {
        // Trigger the update immediately
        registration.waiting.postMessage({ type: 'SKIP_WAITING' });
      }
    }
  },
});
