import React, { useState, useEffect } from 'react';
import fetchHelper from '../Helper/Fetch';
import useAuthStore from '../Store/authStore';
import { enumerate, vehicle_types, states as states_endpoint, lgs as lgs_endpoint } from '../Constants/endpoints';
import useStickerNumber from '../Store/StickerStore'
import { toast } from 'react-toastify';

const EnumerationForm = () => {
    const { token, user } = useAuthStore();
    const { sticker_number } = useStickerNumber();
    const [vehicleTypes, setVehicleTypes] = useState([]);
    const [states, setStates] = useState([]);
    const [localGovernments, setLocalGovernments] = useState([]);
    const [formData, setFormData] = useState({
      name: '',
      address:'',
      email: '',
      phone: '',
      rc: 'NA',
      nationality: 'Nigerian',
      state: '',
      lga: '',
      drivers_license_no: '',
      vehicle_type: '',
      
      vehicle_license: '',
      vehicle_tyres: 'NA',
      sticker_number: sticker_number,
    });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  useEffect(() => {
    const headers = {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token}`
    };
    const get_vehicle_type = async() =>{
      const response = await fetchHelper(vehicle_types, "GET", null, headers)
      console.log(response);
      console.log(response.data);
      setVehicleTypes(response.data); // Assuming 'data' contains the array of vehicle types
    }
    get_vehicle_type();

    //get states
    const get_states = async() =>{
      const response = await fetchHelper(states_endpoint, "GET", null, headers)
      console.log(response);
      console.log(response);
      setStates(response); // Assuming 'data' contains the array of vehicle types
    }
    get_states()

  }, []);

  useEffect(() => {
    setLocalGovernments([]);
    setFormData({
      ...formData,
      lga: ""
    });
    if (formData.state) {
      const headers = {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`
      };
      const get_lgs = async() =>{
        const response = await fetchHelper(lgs_endpoint + "?state=" + formData.state, "GET", null, headers)
        console.log(response);
        console.log(response);
        setLocalGovernments(response); // Assuming 'data' contains the array of vehicle types
      }
      get_lgs()
    }
  }, [formData.state]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    console.log(formData);
    const headers = {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`
    };
    const data = await fetchHelper(enumerate, 'POST', formData, headers);
    console.log(data);
    if(data.status_code=="00"){
      toast.success("Enumeration Successful");
      setTimeout(() => {
        window.location.href="/search";
      }, 2000); // 2000 milliseconds = 2 seconds
    }else if(data.status_code=="02"){
      setTimeout(() => {
        window.location.href="/search";
      }, 2000); // 2000 milliseconds = 2 seconds
      toast.success("Already Enumerated");
      return
    }else{
      toast.error("Enumeration Failed");
      return
    }
  };

  return (
    <div className="min-h-screen bg-green-100 flex justify-center items-center">
      <div className="bg-white shadow-lg rounded-lg px-8 max-w-md w-full">
        <h2 className="text-2xl font-bold text-green-600 mb-2 text-center">Enumeration</h2>
        <form onSubmit={handleSubmit} className="space-y-4">
          {/* First Name */}
          <div>
            <label className="block text-green-700 font-semibold mb-2">Full Name</label>
            <input
              type="text"
              name="name"
              value={formData.name}
              onChange={handleChange}
              className="w-full p-2 border rounded focus:outline-none focus:ring-2 focus:ring-green-400"
              required
            />
          </div>

          <div>
            <label className="block text-green-700 font-semibold mb-2">Address</label>
            <input
              type="text"
              name="address"
              value={formData.address}
              onChange={handleChange}
              className="w-full p-2 border rounded focus:outline-none focus:ring-2 focus:ring-green-400"
              required
            />
          </div>
                   
          {/* Email */}
          <div>
            <label className="block text-green-700 font-semibold mb-2">Email</label>
            <input
              type="email"
              name="email"
              value={formData.email}
              onChange={handleChange}
              className="w-full p-2 border rounded focus:outline-none focus:ring-2 focus:ring-green-400"
            />
          </div>

          {/* Phone */}
          <div>
            <label className="block text-green-700 font-semibold mb-2">Phone</label>
            <input
              type="text"
              name="phone"
              value={formData.phone}
              onChange={handleChange}
              className="w-full p-2 border rounded focus:outline-none focus:ring-2 focus:ring-green-400"
              required
            />
          </div>

          {/* RC */}
          {/* <div>
            <label className="block text-green-700 font-semibold mb-2">RC</label>
            <input
              type="text"
              name="rc"
              value={formData.rc}
              onChange={handleChange}
              className="w-full p-2 border rounded focus:outline-none focus:ring-2 focus:ring-green-400"
            />
          </div> */}

          {/* Nationality */}
          <div>
            <label className="block text-green-700 font-semibold mb-2">Nationality</label>
            <input
              type="text"
              name="nationality"
              value={formData.nationality}
              onChange={handleChange}
              className="w-full p-2 border rounded focus:outline-none focus:ring-2 focus:ring-green-400"
              required
            />
          </div>

          {/* State */}
          <div className="mb-4">
            <label htmlFor="state" className="block text-green-700 font-semibold mb-2">
              State
            </label>
            <input
              list="state-list"
              name="state"
              value={formData.state}
              onChange={handleChange}
              required
              // className="mt-1 block w-full border-gray-300 rounded-md shadow-sm focus:ring-green-500 focus:border-green-500 sm:text-sm"
               className="w-full p-2 border rounded focus:outline-none focus:ring-2 focus:ring-green-400"
            />
            <datalist id="state-list">
              {states.map((stateObj, index) => (
                <option key={index} value={stateObj.state} />
              ))}
            </datalist>
          </div>

          {/* LGA */}
          <div className="mb-4">
            <label htmlFor="lga" className="block text-green-700 font-semibold mb-2">
              Local Government
            </label>
            <input
              list="lga-list"
              name="lga"
              value={formData.lga}
              onChange={handleChange}
              required
              className="w-full p-2 border rounded focus:outline-none focus:ring-2 focus:ring-green-400"
            />
            <datalist id="lga-list">
              {localGovernments?.map((lgaObj, index) => (
                <option key={index} value={lgaObj.local_governments} />
              ))}
            </datalist>
          </div>

         {/* Vehicle Type */}
          <div>
            <label className="block text-green-700 font-semibold mb-2">Vehicle Type</label>
            <select
              name="vehicle_type"
              value={formData.vehicle_type}
              onChange={handleChange}
              required
              // className="mt-1 block w-full border-gray-300 rounded-md shadow-sm focus:ring-green-500 focus:border-green-500 sm:text-sm"\
              className="w-full p-2 border rounded focus:outline-none focus:ring-2 focus:ring-green-400"
            >
              <option value="">Select Vehicle Type</option>
              {vehicleTypes.length > 0 ? (
                vehicleTypes.map((vehicle, index) => (
                  <option key={index} value={vehicle.name}>{vehicle.name}</option>
                    ))
                  ) : (
                    <option value="" disabled>No vehicle types available</option>
                  )}
            </select>
          </div>

          {/* Vehicle License */}
          <div>
            <label className="block text-green-700 font-semibold mb-2">Vehicle License</label>
            <input
              type="text"
              name="vehicle_license"
              value={formData.vehicle_license}
              onChange={handleChange}
              className="w-full p-2 border rounded focus:outline-none focus:ring-2 focus:ring-green-400"
              required
            />
          </div>

          {/* Vehicle Tyres */}
          {/* <div>
            <label className="block text-green-700 font-semibold mb-2">Vehicle Tyres</label>
            <input
              type="text"
              name="vehicle_tyres"
              value={formData.vehicle_tyres}
              onChange={handleChange}
              className="w-full p-2 border rounded focus:outline-none focus:ring-2 focus:ring-green-400"
            />
          </div> */}

          {/* Sticker Number */}
          <div>
            <label className="block text-green-700 font-semibold mb-2">Sticker Number</label>
            <input
              type="text"
              name="sticker_number"
              value={formData.sticker_number}
              onChange={handleChange}
              className="w-full p-2 border rounded focus:outline-none focus:ring-2 focus:ring-green-400"
              required
              readOnly
            />
          </div>

          {/* Submit Button */}
          <div className="text-center">
            <button
              type="submit"
              className="w-full bg-green-500 text-white py-2 px-4 rounded hover:bg-green-600 transition duration-200"
            >
              Submit
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default EnumerationForm;
