import React from 'react';
import '../spinner.css'


const LoadingIndicator = () => {
  return (
    <div className="flex items-center justify-center h-screen">
      <div className="loader spinner"></div>
    </div>
  );
};

export default LoadingIndicator;