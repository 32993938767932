import { create } from 'zustand';

const useStickerStatus = create((set) => ({
  // Initial state
  sticker_status: '',

  // Function to set the sticker number
  setStickerStatus: (sticker_status) => set(() => ({ sticker_status })),

  // Function to reset the sticker number
  resetStickerStatus: () => set(() => ({ sticker_status: '' })),
}));

export default useStickerStatus;
