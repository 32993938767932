import React, { useEffect } from 'react';
import useAuthStore  from "../Store/authStore"
import useModalStore from "../Store/modalState"
import SHFDetails from './SHFDetails'

const SearchResult = ({results, shf_id, sticker_id}) => {
  const { token } = useAuthStore();
  const { openModal } = useModalStore(state => ({
    openModal: state.openModal
  }));

  useEffect(() => {
    console.log(results)
    if (!token) {
      window.location.href = '/';
    }
  }, [token]);

  const handleSHFDetails = (e) => {
    e.preventDefault()
    openModal(
      <div>
        <h1 className="text-xl font-bold">SHF Details</h1>
        <SHFDetails results={results} shf_id={shf_id} />
      </div>
    );
  }
  return (
    <div className="m-1">
      {results && <h1 className={`mt-3 rounded-lg px-3 text-center uppercase text-2xl font-bold ${results?.status === "found" ? "text-green-500" : "text-red-500"}`}>{results?.license_plate}  {results?.status}</h1>}
      {results?.status === "found" ? (
        <div className='px-4'>
          <table>
            <tr>
              <td className="pr-8 font-bold">Sticker: </td>
              <td className={`uppercase font-bold ${results?.sticker === "active" ? "text-green-500" : "text-red-500"}`}>{results?.sticker || ""} </td>
            </tr>
  
            <tr>
              <td className="pr-8 font-bold">SHF: </td>
              <td className={`uppercase font-bold ${results?.shf==="active" ? "text-green-500" : "text-red-500"}`}>{results?.shf || ""} {results?.shf=="active" && <span onClick={handleSHFDetails} className=' bg-black ml-2 rounded-lg px-1 text-white text-sm capitalize'>Show
              </span>}</td>
            </tr>
        </table>

        <hr className='mb-3' />

        <table className='leading-7'>
          <tr>
            <td className="pr-8 font-bold">Full Name: </td>
            <td>{results?.name } </td>
          </tr>
  
          <tr>
            <td className="pr-8 font-bold">State: </td>
            <td>{results?.state } </td>
          </tr>
  
          <tr>
            <td className="pr-8 font-bold">Local Government: </td>
            <td>{results?.lg || "" } </td>
          </tr>
  
          <tr>
            <td className="pr-8 font-bold">Next of Kin: </td>
            <td>{results?.nok } </td>
            
          </tr>
        </table>
        
        <hr className='my-1 border-red-300'/>
  
        <table>
          {/* <tr>
            <td className="pr-8 font-bold">License Plate: </td>
            <td>{results?.license_plate || "" } </td>
          </tr> */}
  
          <tr>
            <td className="pr-8 font-bold">Vehicle Type: </td>
            <td>{results?.vehicle_type || ""} </td>
          </tr>
        </table>
        
        <hr className='my-1 border-red-300'/>
        
        {results.shf=='active' && <>
        <table>
          <tr>
            <td className="pr-8 font-bold">License Plate: </td>
            <td>{results?.license_plate || "" } </td>
          </tr>
  
          <tr>
            <td className="pr-8 font-bold">Vehicle Type: </td>
            <td>{results?.vehicle_type || ""} </td>
          </tr>
        </table>
        </>}
        </div>
      ): 
      <>
       {JSON.stringify(results) === '{}' && 
        <p className='text-gray-800 p-4 text-center'>Use either License Plate Number or
         Reference Number to search</p>}
        {results.status=='not found' && <p className='text-red-500 p-4 text-center'>No results found</p>}

      </>}
      
      
    </div>
  );
};

export default SearchResult;