import React, {useEffect} from 'react';
import Header from '../Component/Header';
import useAuthStore from '../Store/authStore';
import Information from '../Component/Information';

const Profile = () => {
    const { token, user,authReset } = useAuthStore();

    useEffect(() => {
      if (!token) {
        window.location.href = '/';
      }
    }, [token]);

    return (
        <div>
            <Header />
            <div className='mx-2 mt-2'>
                <div className="flex">
                    <div className="w-1/3 bg-gray-300 text-center rounded-sm">Information</div>
                    <div className="w-1/3 bg-gray-400 text-center rounded-sm">Password</div>
                    <div className="w-1/3 bg-gray-500 text-center rounded-sm">Edit Profile</div>                    
                </div>
                <Information />
            </div>


        </div>
    )
}

export default Profile