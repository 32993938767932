import React from 'react';
import ReactDOM from 'react-dom';
import useModalStore from '../Store/modalState';
// import './Modal.css'; // Optional: for additional custom styles if needed

const Modal = () => {
  const { isOpen, content, closeModal } = useModalStore(state => ({
    isOpen: state.isOpen,
    content: state.content,
    closeModal: state.closeModal
  }));

  if (!isOpen) return null;

  return ReactDOM.createPortal(
    <div
      className={`fixed inset-0 bg-gray-800 bg-opacity-50 flex items-center justify-center transition-opacity duration-300 ${isOpen ? 'opacity-100' : 'opacity-0 pointer-events-none'}`}
      onClick={closeModal}
    >
      <div
        className={`bg-white rounded-lg p-6 max-w-sm mx-auto relative transition-transform duration-300 transform ${isOpen ? 'scale-100' : 'scale-90'} shadow-lg`}
        onClick={e => e.stopPropagation()}
        style={{
          maxHeight: '80vh',  // Limit the modal height to 80% of the viewport height
          overflowY: 'auto'   // Enable vertical scrolling if content exceeds the modal height
        }}
      >
        {content}
        <button
          className="absolute top-2 right-2 text-gray-600 hover:text-gray-900 text-2xl"
          onClick={closeModal}
        >
          &times;
        </button>
      </div>
    </div>,
    document.body
  );
};

export default Modal;
