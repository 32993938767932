import React, { useState, useEffect } from 'react';
import fetchHelper from '../Helper/Fetch';
import { searchsticker, validatesticker } from '../Constants/endpoints';
import useAuthStore from '../Store/authStore';
import useStickerNumber from '../Store/StickerStore'

const ExtractStickerNumber = ({ url }) => {
  const { token, user } = useAuthStore();
  const { sticker_number, setStickerNumber, resetStickerNumber } = useStickerNumber();

  useEffect(() => {
    // Create a URL object from the string
    const parsedUrl = new URL(url);
    
    // Use URLSearchParams to extract the 'sticker_number' query parameter
    const params = new URLSearchParams(parsedUrl.search);
    const sticker = params.get('sticker-number');

    setStickerNumber(sticker);
  }, [url]);
  
  return (
    <div>
      {/* {stickerNumber && <>
        
      </>} */}
    </div>
  );
};

export default ExtractStickerNumber;
